<template>
  <el-dialog
    title="查看详情"
    :visible.sync="detailShow"
    width="50%"
    @close="closeDialog"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <a-descriptions
      title=""
      layout="vertical"
    >
      <a-descriptions-item label="活动名称">{{
        eventDetails.eventName
      }}</a-descriptions-item>
      <a-descriptions-item label="活动类型">{{
        eventDetails.eventType_dictText
      }}</a-descriptions-item>
      <a-descriptions-item label="报名时间">{{
        eventDetails.registrationTime
      }}</a-descriptions-item>
      <a-descriptions-item label="举办地点">{{
        eventDetails.location
      }}</a-descriptions-item>
      <a-descriptions-item label="举办单位">{{
        eventDetails.organization
      }}</a-descriptions-item>
      <a-descriptions-item label="举办时间">{{
        eventDetails.eventTime
      }}</a-descriptions-item>
      <a-descriptions-item label="报名人数">{{
        eventDetails.registrationCount
      }}</a-descriptions-item>
      <a-descriptions-item label="限制人数">{{
        eventDetails.limitPeople
      }}</a-descriptions-item>
      <a-descriptions-item label="所属网格">{{
        eventDetails.gridName
      }}</a-descriptions-item>
      <a-descriptions-item label="活动状态">{{
        eventDetails.eventState
      }}</a-descriptions-item>
      <a-descriptions-item label="活动加积分">{{
        eventDetails.pointsRewardOne
      }}</a-descriptions-item>
      <a-descriptions-item label="评价加积分">{{
        eventDetails.pointsRewardTwo
      }}</a-descriptions-item>
      <a-descriptions-item label="发布人">{{
        eventDetails.createBy
      }}</a-descriptions-item>
      <a-descriptions-item label="发布时间">{{
        eventDetails.createTime
      }}</a-descriptions-item>
      <a-descriptions-item label="活动评分">{{
        eventDetails.score
      }}</a-descriptions-item>
      <a-descriptions-item label="活动内容" span="3">{{
        eventDetails.content
      }}</a-descriptions-item>

      <a-descriptions-item label="获奖详情" span="3">
        <a-descriptions layout="vertical">
          <a-descriptions-item
            v-for="(reward, index) in eventDetails.rewards"
            :key="index"
            :label="reward.awardName"
          >
            <div>获奖名额：{{ reward.awardCount }}</div>
            <div>获奖奖品: {{ reward.awardPrize }}</div>
          </a-descriptions-item>
        </a-descriptions>
      </a-descriptions-item>
    </a-descriptions>
    <!-- <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit">保存</a-button>
      <a-button style="margin-left: 20px" @click="closeDialog">取消</a-button>
    </div> -->
  </el-dialog>
</template>
<script>
import api from "./api";

export default {
  data() {
    return {
      detailShow: false,
      eventDetails: {
        eventName: "",
        eventType_dictText: "",
        registrationTime: "",
        location: "",
        eventTime: "",
        organization: "",
        limitPeople: "",
        gridName: "",
        content: "",
        score: "",
        registrationCount: "",
        rewards: [],
        eventState: "",
        createBy: "",
        createTime: "",
      },
    };
  },
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.detailShow = true;
      this.fetchData(record.id);
    },
    // 获取数据
    async fetchData(id) {
      try {
        const res = await api.getById(id);
        if (res.success && res.data) {
          this.eventDetails = res.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    // 提交表单
    onSubmit() {
      this.refreshTable();
      this.closeDialog();
    },
    // 关闭弹窗
    closeDialog() {
      this.detailShow = false;
      this.refreshTable();
    },
    // 刷新表格
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #556bff;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  // padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
</style>
    